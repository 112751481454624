<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput($event)"
            label="text"
            track-by="value"
            :disabled="disabled"
            :multiple="false"
            :select-label="''"
            :readonly="readOnly"
            :selected-label="''"
            :deselect-label="''"
            :searchable="true"
        >
			<span slot="noOptions">
				{{ $t("no_options") }}
			</span>
            <span slot="noResult">
                {{ $t("no_result") }}
            </span>
        </multiselect>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError"
        />
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";


// Other
import qs from "qs";
import HousingBedServices from "@/services/HousingBedServices";

export default {
    props: {
        value: {
            default: null,
        },
        validateError: {
            type: String,
            default: "",
        },
        disabled:{
            type: Boolean,
            default: false,
        },
        readOnly:{
            type: Boolean,
            default: false,
        },
        housing_room_id:{
            type: Number,
            default: null
        }
    },
    data() {
        return {
            selected: null,
            options: []
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.selected = setSelected(newValue, this.options);
            }
        },
        housing_room_id:{
            handler: function () {
                this.clearOptions()
                this.getOptions()
            }
        }
    },
    created() {
        this.getOptions();
    },
    methods: {
        handleInput: handleInput,
        setSelected: setSelected,
        translateNSelected: translateNSelected,
        getOptions() {
            this.clearOptions()
            if (!this.housing_room_id){
                return;
            }
            let config = {
                params: {
                    filter:{
                        housing_room_id: this.housing_room_id
                    },
                    sort:'name',
                    limit:-1
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            HousingBedServices.getAll(config)
                .then((response) => {
                    const data = response.data.data;
                    data.map((item) => {
                        this.options.push({
                            value: item.id,
                            text:item.bed_name + ' - ' + item.type_name,
                        });
                    });
                })
                .then(() => {
                    this.selected = setSelected(this.value, this.options);
                })
        },
        clearOptions() {
            this.selected = null;
            this.options = [];
        }
    }
}
</script>
