<template>
    <div>
        <loading v-show="pageLoading"/>
        <div v-show="!pageLoading">
            <div>
                <ValidationObserver ref="formReservationModalValidate">
                    <b-card :sub-title="$t('personal_info')">
                        <b-row>
                            <b-col cols="4">
                                <ValidationProvider name="name" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('name')">
                                        <b-form-input v-model="form.name" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col  cols="4">
                                <ValidationProvider name="surname" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('surname')">
                                        <b-form-input v-model="form.surname" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4">
                                <ValidationProvider name="gender" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('gender')">
                                        <b-form-input v-model="form.gender" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="email" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('email')">
                                        <b-form-input v-model="form.email" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- <b-col cols="6">
                                <ValidationProvider name="mobile_tel_country_code" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('country_code')">
                                        <multiselect
                                            v-model="formAddress.mobile_tel_country_code"
                                            :options="countryCodeData"
                                            track-by="text"
                                            label="text"
                                            @input="selectedCountryCode"
                                            :searchable="true">
                                        </multiselect>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col> -->
                            <b-col cols="6">
                                <ValidationProvider name="mobile_tel" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('phone_number')">
                                        <b-form-input v-model="form.mobile_tel" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="passport_country_id" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('passport_country')">
                                        <country-selectbox v-model="form.passport_country_id" :disabled="true" :validateError="errors[0]">
                                        </country-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="passport_number" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('passport_number')">
                                        <b-form-input v-model="form.passport_number" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card :sub-title="$t('address_information')" class="mt-3">
                        <b-row>
                            <b-col cols="4">
                                <ValidationProvider name="contact_city_id" rules="" v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label>{{ $t('city_name') }}</label>
                                        <city-selectbox
                                        v-model="form.contact_city_id" :disabled="true" :validateError="errors[0]">
                                        </city-selectbox>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4">
                                <ValidationProvider name="contact_district_id" rules="" v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label>{{ $t('city') }}</label>
                                        <district-outline-selectbox v-model="form.contact_district_id" :city_id.sync="form.contact_city_id" :disabled="true" :validateError="errors[0]">
                                        </district-outline-selectbox>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4">
                                <ValidationProvider name="address_zip_code" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('zip_code')">
                                        <b-form-input v-model="form.address_zip_code" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card :sub-title="$t('reservation_information')" class="mt-3">
                        <b-row>
                            <b-col cols="6">
                                <ValidationProvider name="check_in_date" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('check_in_date')">
                                        <b-form-input v-model="filterSend.check_in_date" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="check_out_date" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('check_out_date')">
                                        <b-form-input v-model="filterSend.check_out_date" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4">
                                <ValidationProvider name="housing_building_name" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('housing_building_name')">
                                        <b-form-input v-model="reservationDataSend.housing_building_name" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4">
                                <ValidationProvider name="housing_room_name" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('housing_room_name')">
                                        <b-form-input v-model="reservationDataSend.housing_room_name" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4">
                                <ValidationProvider name="housing_bed_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('housing_beds')">
                                        <house-bed-selectbox :validate-error="errors[0]" v-model="form.housing_bed_id" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4">
                                <ValidationProvider name="housing_floor" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('floor')">
                                        <b-form-input v-model="reservationDataSend.housing_floor" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4">
                                <ValidationProvider name="type_name" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('type')">
                                        <b-form-input v-model="reservationDataSend.type_name" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4">
                                <ValidationProvider name="fee" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('price')">
                                        <b-form-input v-model="reservationDataSend.fee" readonly :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
							        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                </ValidationObserver>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <b-button @click="saveReservationForm()">
                    {{ $t('submit') }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    // Component
	import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
	import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
	import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
    import HouseBedSelectbox from "@/components/interactive-fields/HouseBedSelectbox.vue";
    import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete"

    // Services
    import roomReservationSystemService from '@/services/roomReservationSystemService'

    // Pages
    import Loading from '@/components/elements/Loading'


    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import countryCodeInputData from "./countryCodeInputData"


    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            Loading,
            CitySelectbox,
            DistrictOutlineSelectbox,
            CountrySelectbox,
            HouseBedSelectbox,
            StaffAutoComplete
        },
        props: {
        reservationData: {
            type: Object,
            required: true,
            default: () => ({})
        },
        filters: {
          type: Object,
          required: true,
          default: () => ({})
        },
    },
        data() {
            return {
                pageLoading: false,
                mask: '',
                countryCodeInputData,
                formAddress: {},
                reservationDataSend: { ...this.reservationData },
                filterSend: { ...this.filters },
                form: {},

            }
        },
        created() {
            this.getData();
        },
        computed: {
        countryCodeData() {
                let countryCode = []
                this.countryCodeInputData.forEach(itm => {
                    countryCode.push({
                        text: itm.text,
                        value: itm.value,
                        code: itm.maxlength ?? null
                    })
                })
                return countryCode
            },
            getReservationToken(){
                return this.$store.getters['roomReservationSystem/getReservationToken']
            }
        },
        methods: {
        async saveReservationForm() {
            this.pageLoading = true;
            const isValid = await this.$refs.formReservationModalValidate.validate();
            if(!isValid){
                this.pageLoading = false;
                return
            }

            let { check_in_date, check_out_date } = this.filterSend;

            let formData = {
                    check_in_date,
                    check_out_date,
                    housing_bed_id: this.form.housing_bed_id,
            };
                try {
                  await this.$store.dispatch('roomReservationSystem/setReservationToken');
                  if(!this.getReservationToken){
                    return
                  }
                    const response = await roomReservationSystemService.save(formData);
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('createFormSuccess', true);
                    this.$emit('closeSuccessModal');
                    this.$router.push('/my-housing-reservations')
                } catch (e) {
                    this.showErrors(e, this.$refs.formReservationModalValidate);
                } finally {
                    this.pageLoading = false;
                }
    },
    async getData() {
            let user = await JSON.parse(localStorage.getItem('user'));
            const response = await roomReservationSystemService.getStudentInformation(user.id)
            this.form = response.data.data
        }
}

    }
</script>
